import React from 'react'
import { kebabCase } from 'lodash'
import Helmet from 'react-helmet'
import { Link, graphql } from 'gatsby'
import Layout from '../../components/Layout'

const TagsPage = ({
  data: {
    allMarkdownRemark: { group },
    site: {
      siteMetadata: {
        title,
        host
      },
    },
  },
  location
}) => {
  const metaTitle = `Štítky | ${title}`;
  const metaUrl = `${host}${location.pathname || '/'}`;

  return (
    <Layout>
      <Helmet title={metaTitle}>
        <meta property="og:title" content={metaTitle}/>
        <meta property="og:url" content={metaUrl}/>
        <link rel="canonical" href={metaUrl} />
        <meta name="twitter:title" content={metaTitle} />
      </Helmet>
      <div className="main uk-width-auto section" id="main">
        <div className="widget Label" data-version="2" id="Label1">
          <div className="filtering_labels uk-grid uk-margin-bottom">
            <div className="breadcrumb uk-width-expand@m">
              <ul className="uk-breadcrumb">
                <li><a href="/">Úvodní stránka</a></li>
                <li className="uk-disabled"><span>Štítky</span></li>
              </ul>
            </div>
          </div>
        </div>
        <div className="widget Label" data-version="3" id="Label2">
          <div className="filtering_labels uk-grid uk-margin-bottom">
            <div className="select_label uk-width-expand@m">
              <ul className="uk-list uk-list-inline">
                {group.map(tag => (
                  <li key={tag.fieldValue}>
                    <Link to={`/tags/${kebabCase(tag.fieldValue)}/`} className="uk-button uk-button-default uk-margin-bottom">
                      {tag.fieldValue} ({tag.totalCount})
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </Layout>
)};

export default TagsPage

export const tagPageQuery = graphql`
  query TagsQuery {
    site {
      siteMetadata {
        title
        host
      }
    }
    allMarkdownRemark(limit: 1000) {
      group(field: frontmatter___tags) {
        fieldValue
        totalCount
      }
    }
  }
`
